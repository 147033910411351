<template>
  <div>
    <div style="margin: 20px 0 0px 20px">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="role-main">
      <div class="search" v-if="formData.length">
        <div class="search-item">
          <div class="name">
            项目名称:
          </div>
          <div class="cont">
            <div class="values" >
              <div :class="curProjectIndex == index ? 'active' : ''" v-for="(item,index) in formData" :key="item.id + 'project'" @click="changeSearch(index,'curProjectIndex')">{{item.name}}</div>
            </div>
          </div>
        </div>

        <div class="search-item">
          <div class="name">
            批次名称:
          </div>
          <div class="cont">
            <div class="values">
<!--              <div :class="curBatchIndex == index ? 'active' : ''" v-for="(item,index) in formData[curProjectIndex].children" :key="item.id + 'batch'" @click="changeSearch(index,'curBatchIndex')">{{item.name}}({{item.id}})</div>-->
              <div :class="curBatchIndex == index ? 'active' : ''" v-for="(item,index) in formData[curProjectIndex].children" :key="item.id + 'batch'" @click="changeSearch(index,'curBatchIndex')">{{item.name}}</div>
            </div>
          </div>
        </div>
        <div class="search-item" v-if="formData[curProjectIndex].children[curBatchIndex].children.length > 1">
          <div class="name">
            工序名称:
          </div>
          <div class="cont">
            <div class="values" >
              <div :class="curStepIndex == index ? 'active' : ''" v-for="(item,index) in formData[curProjectIndex].children[curBatchIndex].children" :key="item.id + 'step'" @click="changeSearch(index,'curStepIndex')">{{item.name}}</div>
            </div>

          </div>
        </div>
      </div>
      <div class="task-cont" v-if="taskDeatil.project && taskDeatil.project.id ">
        <div class="task-cont-top">
          <span class="tit">{{taskDeatil.project.name}}（ID：{{ taskDeatil.project_id }}）</span>
          <span>{{taskDeatil.start_time}} ~ {{taskDeatil.end_time}}</span>
        </div>
        <WorkList :taskId="taskDeatil.id" :receive_count="taskDeatil.receive_count" :stepType="taskDeatil.step && taskDeatil.step.type" :projectId="taskDeatil.project && taskDeatil.project.id"></WorkList>
      </div>
    </div>
    <div class="no-data" v-if="!formData.length && !loading">
      <img src="../../../assets/images/new_icon/nodata.png" width="158" height="82" />
      <p>暂无数据</p>
    </div>
<!--    <Table border :columns="columns" :loading="loading" :data="data"></Table>-->
<!--    <div class="page">-->
<!--      <Page :total="total" @on-change="changePage" @on-page-size-change="changePageSize" :current="page" show-total show-sizer show-elevator></Page>-->
<!--    </div>-->
  </div>

</template>

<script>
// import DeleModal from '../../../components/deleteModal.vue';
// import taskOp from './components/tasks/task-op.vue';
// import progressOp from './components/tasks/progress.vue';
import util from "@/utils/tools";
import WorkList from './components/detail/workList.vue';
export default {
  name: "role",
  data(){
    return{
      columns: [
        {
          title: this.$t('task_table_taskid'),
          key:'id'
        },
        {
          title: this.$t('task_table_project'),
          key: 'project_id',
          minWidth: 60,
          render: (h, para) => {
            console.log(para.row.id,'para.row.id')
            return h('router-link', {
              props: {
                to: {
                  name: 'task-detail',
                  params: {
                    id: para.row.id,
                    tab: 'work-list',
                    subtab: 'all'
                  }
                }
              },
              style:{
                color:'#2db7f5',
              }
            }, para.row.project.name);
          },
        },
        {
          title: this.$t('task_table_batch'),
          key: 'batch_id',
          minWidth: 60,
          render: (h, para) => {
                return h("span", util.base64decode(para.row.batch.name) + '('+para.row.batch.id+')');
          },
        },
        {
            title: this.$t('task_table_step'),
            key: "step_type",
            align: "center",
            render: (h, para) => {
              //console.log(para.row.step.type,this.step_types)
              //return h("span", this.stepList.length && this.step_types[para.row.step.type]);
              return h("span", para.row.step.name);
            },
        },
        {
            title: this.$t('task_table_type'),
            key: "category_id",
            align: "center",
            maxWidth: 100,
            render: (h, para) => {
                return h("span", para.row.project.category.name);
            },
            // filters: [],
            // filterMultiple: false,
            // filterMethod: () => true
        },
        {
            title: this.$t('task_table_amount'),
            key: "amount",
            align: "center",
            maxWidth: 170,
            minWidth: 120,
            render: (h, para) => {
                if (para.row.stat) {
                    return h("span", (para.row.stat.work_count ? para.row.stat.work_count : '0') + ' / ' + (para.row.stat.amount ? para.row.stat.amount : 0) + ' / ' + (para.row.batch.amount ? para.row.batch.amount : 0));
                } else {
                    return h("span", '-- / ' + '-- / ' + (para.row.batch.amount ? para.row.batch.amount : 0));
                }
            },
            renderHeader: (h, params) => {
                return h('span', [
                    h('Poptip', {
                        'class': {
                            tablePop: true,
                        },
                        props: {
                            trigger: "hover",
                            title: this.$t('admin_fields_description'),
                            content: "",
                            transfer: true,
                            placement: 'right-start',
                        },
                        scopedSlots: {
                            content: () => {
                                return h('span', {
                                }, [
                                    h('div', this.$t('admin_execute_description')),
                                    h('div', this.$t('admin_placeholder_description')),
                                ]);
                            }
                        }
                    }, [
                        h('span', this.$t('admin_working_out')),
                        h('Icon', {
                            style: {
                                marginLeft: '3px',
                                marginTop: '1px',
                                verticalAlign: 'top'
                            },
                            props: {
                                type: 'ios-help-circle-outline',
                                size: 16
                            },
                        }),

                    ])
                ]);
            }
        },
        {
            title: this.$t('task_table_progress'),
            key: "statistics",
            align: "left",
            maxWidth: 230,
            minWidth: 50,
            render: (h, para) => {
                // return h('div', [
                //     h('div', [
                //         h('div', (para.row.stat.work_count ? para.row.stat.work_count : '0') + ' / ' + (para.row.stat.amount ? para.row.stat.amount : 0)),
                //         h('Tooltip', {
                //             props: {
                //                 content: this.getProcess(para.row) + '%',
                //                 placement: 'top'
                //             },
                //             'class': 'tool_tip',
                //             style: {
                //                 display: 'inline'
                //             }
                //         }, [
                //             h('Progress', {
                //                 props: {
                //                     percent: this.getProcess(para.row),
                //                     status: "active",
                //                     strokeWidth: 3
                //                 },
                //             }, '')
                //         ]),

                //     ]),
                // ]);
                return h('div', [
                    h('div', [
                        h('Tooltip', {
                            props: {
                                content: (para.row.stat.work_count ? para.row.stat.work_count : '0') + ' / ' + (para.row.stat.amount ? para.row.stat.amount : 0) + ' / ' + (para.row.batch.amount ? para.row.batch.amount : 0),
                                placement: 'top-start',
                                transfer: true
                            },
                            'class': 'tool_tip',
                            style: {
                                //display: 'inline'
                                width: '100%'
                            }
                        }, [
                            h('div', [
                                h(progressOp, {
                                    props: {
                                        row: para.row,
                                    }
                                })
                            ])

                        ]),
                    ]),
                ]);
            },
            renderHeader: (h, params) => {
                return h('span', [
                    h('Poptip', {
                        'class': {
                            tablePop: true,
                        },
                        props: {
                            trigger: "hover",
                            title: this.$t('admin_fields_description'),
                            transfer: true,
                            placement: 'right-start',
                        },
                        scopedSlots: {
                            content: () => {
                                return h('span', {
                                }, [
                                    h('div', this.$t('admin_execute_description')),
                                ]);
                            }
                        }
                    }, [
                        h('span', this.$t('admin_schedule')),
                        h('Icon', {
                            style: {
                                marginLeft: '3px',
                                marginTop: '1px',
                                verticalAlign: 'top'
                            },
                            props: {
                                type: 'ios-help-circle-outline',
                                size: 16
                            },
                        }),
                    ])
                ]);
            }
        },
        /*{
            title: this.$t('task_table_performance'),
            key: "statistics",
            align: "center",
            maxWidth: 130,
            render: (h, para) => {
                if (para.row.step.type === '1') {
                    return h("span", (para.row.stat.allowed_count ? para.row.stat.allowed_count : '0') + ' / ' + (para.row.stat.work_count ? para.row.stat.work_count : '0'));
                } else if (para.row.step.type === '2') {
                    return h("span", (para.row.stat.allow_count ? para.row.stat.allow_count : '0') + ' / ' + (para.row.stat.work_count ? para.row.stat.work_count : '0'));
                } else {
                    //return h("span", this.getStatistics(para.row.stat));
                    let work_count = para.row.stat.work_count ? para.row.stat.work_count : 0;
                    let refused_count = para.row.stat.refused_count ? para.row.stat.refused_count : 0;
                    let allowed_count = para.row.stat.allowed_count ? para.row.stat.allowed_count : 0;
                    return allowed_count + '  /  ' + work_count;
                }
            },
            renderHeader: (h, params) => {
                return h('span', [
                    h('Poptip', {
                        'class': {
                            tablePop: true,
                        },
                        props: {
                            trigger: "hover",
                            title: this.$t('admin_fields_description'),
                            transfer: true,
                            placement: 'right-start',
                        },
                        scopedSlots: {
                            content: () => {
                                return h('span', {
                                }, [
                                    h('div', this.$t('admin_performance_execute_description')),
                                    h('div', this.$t('admin_performance_audit_description')),
                                    h('div', this.$t('admin_performance_qc_description')),
                                ]);
                            }
                        }
                    }, [
                        h('span', this.$t('admin_performance')),
                        h('Icon', {
                            style: {
                                marginLeft: '3px',
                                marginTop: '1px',
                                verticalAlign: 'top'
                            },
                            props: {
                                type: 'ios-help-circle-outline',
                                size: 16
                            },
                        }),
                    ])
                ]);
            }
        },*/
        {
            title: this.$t('task_table_endtime'),
            key: 'end_time',
            align: 'center',
            maxWidth: 120,
            minWidth: 40,
            render: (h, params) => {
                return h('Tooltip', {
                    props: {
                        placement: 'top-start',
                        transfer: true
                    },
                    scopedSlots: {
                        content: () => {
                            return h('span', {}, [
                                h('div', this.$t('admin_start_time') + ': ' + util.timeFormatter(new Date(+params.row.start_time * 1000), 'yyyy-MM-dd')),
                                h('div', this.$t('admin_end_time') + ': ' + util.timeFormatter(new Date(+params.row.end_time * 1000), 'yyyy-MM-dd')),
                            ]);
                        }
                    },
                    style: {
                        display: 'inline'
                    }
                }, [
                    h('span', util.timeFormatter(new Date(+params.row.end_time * 1000), 'yyyy-MM-dd'))
                ]);
            },
            sortable: 'custom',
        },
        {
            title: this.$t('task_table_operation'),
            align: "left",
            maxWidth: 130,
            render: (h, para) => {
                return h('div', [
                    h(taskOp, {
                        props: {
                            project_id: para.row.project_id,
                            task_id: para.row.id,
                            step_type: para.row.step.type,
                            user_count: para.row.user_count,
                            has_refused_count: para.row.statUser.has_refused_count,
                            has_refuse_submited_count: para.row.statUser.has_refuse_submited_count,
                            has_refuse_wait_count: para.row.statUser.has_refuse_wait_count,
                            has_difficult_count: para.row.statUser.has_difficult_count,
                            has_refuse_count: para.row.statUser.has_refuse_count,
                            has_execute_count: para.row.stat.has_execute_count,
                        }
                    })
                ]);
            },
            renderHeader: (h, params) => {
                return h('span', [
                    h('Poptip', {
                        props: {
                            trigger: "hover",
                            title: this.$t('admin_fields_description'),
                            transfer: true,
                            placement: 'right-start',
                        },
                        scopedSlots: {
                            content: () => {
                                return h('span', {}, [
                                    //h('div', this.$t('admin_setting_members')),
                                    h('div', this.$t('task_tasks_operation_execute_tip')),
                                    h('div', this.$t('task_tasks_operation_refuse_tip')),
                                    h('div', this.$t('task_tasks_operation_refused_tip')),
                                    h('div', this.$t('task_tasks_operation_difficute_tip')),
                                ]);
                            }
                        }
                    }, [
                        h('span', this.$t('admin_handle')),
                        h('Icon', {
                            style: {
                                marginLeft: '3px',
                                marginTop: '1px',
                                verticalAlign: 'top'
                            },
                            props: {
                                type: 'ios-help-circle-outline',
                                size: 16
                            },
                        }),
                    ])
                ]);
            }
        }
      ],
      data: [],
      loading:true,
      deleteComfirmModal:true,
      deleModalStatus:false,
      modalLoading:false,
      curDeleData:{},
      page:1,
      pageSize:10,
      total:0,
      modalStatus:false,
      addBtnLoading:false,
      type:'',
      modalTit:'',
      step_types: {},
      categories: [],
      projectMoreStatus:false,
      batchMoreStatus:false,
      categoryList:[],  //项目类型
      curCategoryId:'-1',
      projectList:[], //项目名称
      curProjectIndex:0,
      batchList:[], //批次名称
      curBatchIndex:0,
      stepList:[],  //工序名称
      curStepIndex:0,
      receiveStatuses:[], //领取状态
      curReceiveId:'1',
      taskDeatil:{},
      formData:[],
    }
  },
  components:{
    WorkList
  },
  mounted(){
    console.log('23232323')
    // this.getProjectList();
    this.getTaskForm();
  },
  methods:{
    more(name){ //更多
      this[name] = !this[name];
    },
    getTaskForm(){
      this.loading = true;
      this.api.task.myTaskForm().then((res)=>{
        this.loading = false;

        this.formData = res.tree;
        this.formData.forEach((item)=>{
          item.children.forEach((sItem)=>{
            sItem.name = util.decrypt(sItem.name);
          })
        })

        this.getList();
        //this.categories = res.categories;
      }).catch((err)=>{
        this.loading = false;
      })
    },
    getList(){
      this.loading = true;
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        project_id:this.formData[this.curProjectIndex].id,
        batch_id:this.formData[this.curProjectIndex].children[this.curBatchIndex].id,
        step_id:this.formData[this.curProjectIndex].children[this.curBatchIndex].children[this.curStepIndex].id,
      };
      this.api.task.taskTasks(params).then((res)=>{
        this.loading = false;
        this.taskDeatil = res.list[0];

        this.taskDeatil.batch.name = util.decrypt(this.taskDeatil.batch.name);

        this.taskDeatil.start_time = util.timeFormatter(
            new Date(+res.list[0].start_time * 1000),
            'yyyy-MM-dd'
        );
        this.taskDeatil.end_time = util.timeFormatter(
            new Date(+res.list[0].end_time * 1000),
            'yyyy-MM-dd'
        );

        // this.data = res.list;

        // this.getDetail(res.list[0].id);

        //this.categories = res.categories;
      }).catch((err)=>{
        this.loading = false;
      })
    },
    changePage(page){
      console.log(page,'changePage')
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getList();
    },
    changeSearch(index,name){
      this[name] = index;
      if(name == 'curProjectIndex'){
        this.curStepIndex = 0;
        this.curBatchIndex = 0;
      }else if(name == 'curBatchIndex'){
        this.curStepIndex = 0;
      }
      this.getList();
    },
  }
}
</script>

<style scoped lang="scss">
.role-main{

  .search{
    margin: 20px;
    padding:20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    border-radius: 4px;
    .search-item{
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;

      .name{
        padding: 5px 0;
        width: 75px;
        text-align: right;
      }
      .cont{
        margin-left: 15px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        .values{
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          transition: max-height 0.3s linear;
          overflow: hidden;

          >div{
            padding: 5px 10px;
            //margin: 0 10px 10px 5px;
            cursor: pointer;
          }
          >div.active{
            background-color: #2d8cf0;
            color:#FFFFFF;
            border-radius: 4px;
          }
        }
        .more{
          padding: 5px 0;
          width: 80px;
          text-align: right;
          cursor: pointer;
          .more-icon{
            transition: transform 0.3s linear;
          }
        }
      }
    }
  }
  .task-cont{
    margin: 20px;
    padding:20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    border-radius: 4px;

    .task-cont-top{
      height: 60px;
      line-height: 60px;
      font-size: 14px;
      border-bottom: 1px solid #dcdee2;

      .tit{
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
  .top{
    text-align: left;
    margin-bottom: 20px;
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
}
.no-data{
  margin: 0 20px;
  //width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #FFFFFF;
  >p{
    margin-top: 35px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
  }
}
</style>
